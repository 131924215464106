import '@/styles/globals.scss'
import { AppProps } from 'next/app'
import { useEffect, useState } from 'react'
import { GameCacheProvider } from '@/contexts/GameCache'
import { getAllGames, Game } from '@/lib/games'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const { asPath, pathname } = router
  const linkUrl = `https://www.h5freegames.com${asPath}`
  const noIndex = pathname === '/search' || pathname === '/played'

  return (
    <main>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        {noIndex && <meta name="robots" content="noindex" />}
        <link rel="canonical" href={linkUrl} />
        <link rel="alternate" href={linkUrl} hrefLang="x-default" />
        <script
          data-rh="true"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{"@context":"https://schema.org","@type":"WebSite","url":"https://www.h5freegames.com/","logo":"https://www.h5freegames.com/logo.png"}`,
          }}
        />
      </Head>
      <div className="container">
        {/* <GameCacheProvider initialGames={pageProps.gamesList}> */}
        <Navbar data={pageProps.gamesList} />
        <Component {...pageProps} />
        {/* </GameCacheProvider> */}
      </div>
      <Footer />
    </main>
  )
}

export default MyApp
