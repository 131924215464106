import { FC } from 'react'
import styles from './index.module.scss'

const Footer: FC = () => {
  return (
    <footer className={styles.footer}>
      <div>
        <a href={`mailto:service@pegasus-space.com`}>{'Contact Us'}</a>
        <a href="/terms-of-service.html">{'Terms of Service'}</a>
        <a href="/privacy-policy.html">{'Privacy Policy'}</a>
      </div>
      <span> {`© ${new Date().getFullYear()} All Rights Reserved`}</span>
    </footer>
  )
}

export default Footer
