export const generatePaths = (array: any[]) => {
  return array.map((item) => {
    const path = item.title.trim().toLowerCase().replace(/\s+/g, '-')
    return { ...item, path }
  })
}

export const getInfoByPath = (array: any[], slug: string) => {
  return array.find((item) => item.slug === slug)
}

export const convertTagsToArray = (obj: any) => {
  if (obj.category || obj.tags) {
    return {
      ...obj,
      category: obj.category.split(',').map((item: string) => item.trim()),
      tags: obj.tags.split(',').map((tag: string) => tag.trim()),
    }
  } else {
    return obj
  }
}

export const resizeIframe = (iframe: HTMLIFrameElement) => {
  const iframeDocument =
    iframe.contentDocument || iframe.contentWindow?.document
  if (iframeDocument) {
    const observer = new MutationObserver(() => {
      const contentHeight = iframeDocument.documentElement.scrollHeight
      if (contentHeight) {
        iframe.style.height = contentHeight + 'px'
      }
    })

    observer.observe(iframeDocument.documentElement, {
      childList: true,
      subtree: true,
      characterData: true,
    })
  }
}

interface GameItem {
  title: string
  [key: string]: any // 允许其他任意属性
  isCategory?: boolean // 标记是否通过分类搜索得到的结果
}

export const getGamesBySearchAndCategory = (
  array: GameItem[],
  search?: string,
  categoryFilter?: string,
  tagFilter?: string
): GameItem[] => {
  // 处理搜索场景
  if (search) {
    const searchTerm = search.toLowerCase()
    // 1. 找出所有匹配项（包括title，category，tags 匹配的）
    const allMatches = array
      .map((item) => {
        const titleMatch = item.title.toLowerCase().includes(searchTerm)
        const categories = convertTagsToArray(item).category || []
        const tags = convertTagsToArray(item).tags || []
        const categoryMatch = categories.some((cat: string) =>
          cat.toLowerCase().includes(searchTerm)
        )
        const tagMatch = tags.some((tag: string) =>
          tag.toLowerCase().includes(searchTerm)
        )

        // 如果title和category,tags 都匹配，优先级为 title > category > tags
        return {
          ...item,
          isCategory: categoryMatch,
          isTag: tagMatch,
          matchesTitle: titleMatch,
          matchesCategory: categoryMatch,
          matchesTag: tagMatch,
        }
      })
      .filter(
        (item) => item.matchesTitle || item.matchesCategory || item.matchesTag
      )

    // 2. 优先返回title匹配的结果，但如果同时匹配 category 或 tags 则标记isCategory 或 isTag为true
    const titleMatches = allMatches.filter((item) => item.matchesTitle)
    if (titleMatches.length > 0) {
      return titleMatches.map(
        ({ matchesTitle, matchesCategory, matchesTag, ...item }) => item
      )
    }

    // 3. 没有title匹配但有category,tags 匹配的结果
    const categoryMatches = allMatches.filter((item) => item.matchesCategory)
    if (categoryMatches.length > 0) {
      return categoryMatches.map(
        ({ matchesTitle, matchesCategory, ...item }) => item
      )
    }

    const tagMatches = allMatches.filter((item) => item.matchesTag)
    if (tagMatches.length > 0) {
      return tagMatches.map(({ matchesTitle, matchesTag, ...item }) => item)
    }

    // 以上都无结果，返回空数组
    return []
  }

  // 非搜索场景，通过category筛选
  if (categoryFilter) {
    const categoryTerm = categoryFilter.toLowerCase().replace(/-/g, ' ')
    return array
      .filter((item) => {
        const categories = convertTagsToArray(item).category || []
        return categories.some((cat: string) =>
          cat.toLowerCase().includes(categoryTerm)
        )
      })
      .map((item) => ({ ...item, isCategory: true }))
  }

  // 非搜索场景，通过 tag 筛选
  if (tagFilter) {
    const categoryTerm = tagFilter.toLowerCase().replace(/-/g, ' ')
    return array
      .filter((item) => {
        const tags = convertTagsToArray(item).tags || []
        return tags.some((tag: string) =>
          tag.toLowerCase().includes(categoryTerm)
        )
      })
      .map((item) => ({ ...item, isTag: true }))
  }

  // 既无搜索词也无分类筛选，返回空数组
  return []
}
