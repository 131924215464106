import * as Icons from '@/components/Icons'

export const singlePage = [
  {
    icon: <Icons.HomeIcon />,
    label: 'Home',
    slug: '',
    typeId: '',
  },
  {
    icon: <Icons.RecentlyPlayedIcon />,
    label: 'Recently played',
    slug: 'played',
    typeId: '',
  },
  {
    icon: <Icons.RecommendedIcon />,
    label: 'Recommended',
    slug: 'recommended',
    typeId: '002',
  },
  {
    icon: <Icons.HotIcon />,
    label: 'Hot Games',
    slug: 'hot-games',
    typeId: '003',
  },
]

export const gameCategories = [
  {
    icon: <Icons.PlayerIcon />,
    label: '2 Player',
    slug: '2-player',
    typeId: '004',
  },

  {
    icon: <Icons.TDIcon />,
    label: '3D',
    slug: '3d',
    typeId: '006',
  },
  {
    icon: <Icons.ActionIcon />,
    label: 'Action',
    slug: 'action',
    typeId: '007',
  },
  {
    icon: <Icons.AdventureIcon />,
    label: 'Adventure',
    slug: 'adventure',
    typeId: '008',
  },
  {
    icon: <Icons.ArcadeIcon />,
    label: 'Arcade',
    slug: 'arcade',
    typeId: '009',
  },

  {
    icon: '👦',
    label: 'Boys',
    slug: 'boys',
    typeId: '012',
  },
  {
    icon: <Icons.ClickerIcon />,
    label: 'Clicker',
    slug: 'clicker',
    typeId: '013',
  },
  {
    icon: '👧',
    label: 'Girls',
    slug: 'girls',
    typeId: '014',
  },
  {
    icon: <Icons.HypercasualIcon />,
    label: 'Hypercasual',
    slug: 'hypercasual',
    typeId: '015',
  },
  {
    icon: <Icons.MultiplayerIcon />,
    label: 'Multiplayer',
    slug: 'multiplayer',
    typeId: '016',
  },
  {
    icon: <Icons.PuzzleIcon />,
    label: 'Puzzle',
    slug: 'puzzle',
    typeId: '017',
  },
  {
    icon: <Icons.RacingIcon />,
    label: 'Racing',
    slug: 'racing',
    typeId: '018',
  },
  {
    icon: <Icons.ShootingIcon />,
    label: 'Shooting',
    slug: 'shooting',
    typeId: '019',
  },
  {
    icon: <Icons.SportsIcon />,
    label: 'Sports',
    slug: 'sports',
    typeId: '020',
  },
]
