import React, { FC, useState, useEffect, useRef } from 'react'
import { getGamesBySearchAndCategory } from '@/utils/helper'
import Link from 'next/link'
import Image from 'next/image'
import { singlePage, gameCategories } from '@/contexts/Category'
import styles from './index.module.scss'
import logo from '@/images/logo_w.png'
import * as Icons from '@/components/Icons'
import { useRouter } from 'next/router'

const Navbar: FC<{ data: any }> = ({ data }) => {
  const router = useRouter()
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [isTyping, setIsTyping] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [results, setResults] = useState<any[]>([])
  const timeoutRef = useRef<NodeJS.Timeout>()

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && inputValue) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      router.push(`/search?q=${inputValue}`)
    }
  }
  // 搜索函数
  const performSearch = async (searchTerm: string) => {
    try {
      setIsSearching(true)
      const result = getGamesBySearchAndCategory(data, searchTerm as string, '')
      setResults(result)
    } catch (error) {
      console.error('搜索失败:', error)
      setResults([])
    } finally {
      setIsSearching(false)
    }
  }

  useEffect(() => {
    if (inputValue.length < 2) {
      setResults([]) // 清空不足最小长度的结果
      return
    }

    setIsTyping(true)

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    // 动态防抖时间：输入越长，等待时间越短
    timeoutRef.current = setTimeout(
      () => {
        setIsTyping(false)
        performSearch(inputValue)
      },
      inputValue.length > 5 ? 300 : 600
    )

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [inputValue])

  const toggleMenu = () => {
    window.gtag('event', 'click_navbar', {
      type: !isOpen ? 'open' : 'close',
    })
    setIsOpen(!isOpen)
  }

  const clickGame = (type: string, id: string, title: string) => {
    window.gtag('event', 'click_game_navbar', {
      game_type: type,
      game_id: id,
      game_name: title,
    })
  }
  // 路由切换时需更新状态
  useEffect(() => {
    setIsOpen(false)
  }, [router])

  return (
    <div className={styles.navbar}>
      <Link href="/" className={styles.logo}>
        <img src={logo.src} alt="H5freegames logo" />
        <span>H5FreeGames</span>
      </Link>
      <div className={styles.btn} onClick={toggleMenu}>
        <Icons.NavbarIcon />
      </div>
      {isOpen && (
        <div className={styles.mask}>
          <div className={styles.menuList}>
            {/* 返回+搜索框 */}
            <div className={styles.fnBox}>
              <div className={styles.back} onClick={toggleMenu}>
                <Icons.BackIcon />
              </div>
              <div className={styles.search}>
                <Icons.SearchIcon />
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Search"
                  disabled={isSearching}
                />
                {/* 清除搜索 */}
                {inputValue && (
                  <div
                    className={styles.clear}
                    onClick={() => setInputValue('')}
                  >
                    {'x'}
                  </div>
                )}
              </div>
            </div>

            {/* 显示搜索结果 */}
            {results.length > 0 ? (
              <div className={styles.searchResults}>
                {results.map((result, index) => (
                  <Link key={index} href={`/game/${result.slug}`}>
                    <Image
                      src={result.thumb}
                      alt={result.title}
                      width={60}
                      height={60}
                    />
                    <span> {result.title}</span>
                  </Link>
                ))}
              </div>
            ) : (
              <>
                {/* 分类目录 */}
                <div className={styles.menu}>
                  {singlePage.map((item, index) => (
                    <Link
                      href={`/${item.slug}`}
                      key={index + item.label}
                      //   onClick={() => clickGame(item.typeId, item.id, item.title)}
                    >
                      {item.icon} <span>{item.label}</span>
                    </Link>
                  ))}
                </div>
                <i></i>
                <div className={styles.menu}>
                  {gameCategories.map((item, index) => (
                    <Link
                      href={`/c/${item.slug}`}
                      key={index + item.label}
                      // onClick={() => store.setLabel(item.label)}
                      //   onClick={() => clickGame(item.typeId, item.id, item.title)}
                    >
                      {item.icon} <span>{item.label}</span>
                    </Link>
                  ))}
                </div>
                <i></i>
                {/* tag 页 */}
                <div className={styles.menu}>
                  <Link href={`/tags`}>
                    <Icons.TagsIcon /> <span>Tags</span>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Navbar
