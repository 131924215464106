export const NavbarIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 22 22"
      focusable="false"
      aria-hidden="true"
      fill="#fff"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5502 4.33335C7.25947 4.33335 4.59184 7.00099 4.59184 10.2917C4.59184 13.5824 7.25947 16.25 10.5502 16.25C13.8409 16.25 16.5085 13.5824 16.5085 10.2917C16.5085 7.00099 13.8409 4.33335 10.5502 4.33335ZM2.42517 10.2917C2.42517 5.80437 6.06286 2.16669 10.5502 2.16669C15.0375 2.16669 18.6752 5.80437 18.6752 10.2917C18.6752 12.1448 18.0548 13.853 17.0105 15.2199L23.7745 21.984C24.1976 22.4071 24.1976 23.093 23.7745 23.5161C23.3515 23.9391 22.6655 23.9391 22.2425 23.5161L15.4784 16.752C14.1114 17.7963 12.4033 18.4167 10.5502 18.4167C6.06286 18.4167 2.42517 14.779 2.42517 10.2917ZM20.8418 6.50002C20.2435 6.50002 19.7585 6.98505 19.7585 7.58335C19.7585 8.18166 20.2435 8.66669 20.8418 8.66669L23.0085 8.66669C23.6068 8.66669 24.0918 8.18166 24.0918 7.58335C24.0918 6.98505 23.6068 6.50002 23.0085 6.50002L20.8418 6.50002ZM17.5918 3.25002C17.5918 2.65171 18.0769 2.16669 18.6752 2.16669H23.0085C23.6068 2.16669 24.0918 2.65171 24.0918 3.25002C24.0918 3.84833 23.6068 4.33335 23.0085 4.33335L18.6752 4.33335C18.0769 4.33335 17.5918 3.84833 17.5918 3.25002ZM21.9252 10.8334C21.3269 10.8334 20.8418 11.3184 20.8418 11.9167C20.8418 12.515 21.3269 13 21.9252 13H23.0085C23.6068 13 24.0918 12.515 24.0918 11.9167C24.0918 11.3184 23.6068 10.8334 23.0085 10.8334H21.9252Z"
      ></path>
    </svg>
  )
}
export const BackIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      aria-hidden="true"
      fill="#fff"
      width="24"
      height="24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33006 18.7424C8.74008 19.1124 9.37242 19.08 9.74241 18.6699C10.1124 18.2599 10.08 17.6276 9.66993 17.2576L4.95171 13L21 13C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11L4.9517 11L9.66993 6.74242C10.08 6.37243 10.1124 5.7401 9.74241 5.33007C9.37242 4.92004 8.74008 4.88759 8.33006 5.25759L2.78909 10.2576C1.73696 11.207 1.73696 12.793 2.78909 13.7424L8.33006 18.7424Z"
      ></path>
    </svg>
  )
}
export const SearchIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      aria-hidden="true"
      fill="#ddd"
      width="24"
      height="24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 4C6.91015 4 4 6.91015 4 10.5C4 14.0899 6.91015 17 10.5 17C14.0899 17 17 14.0899 17 10.5C17 6.91015 14.0899 4 10.5 4ZM2 10.5C2 5.80558 5.80558 2 10.5 2C15.1944 2 19 5.80558 19 10.5C19 15.1944 15.1944 19 10.5 19C5.80558 19 2 15.1944 2 10.5Z"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2929 15.2929C15.6834 14.9024 16.3166 14.9024 16.7071 15.2929L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L15.2929 16.7071C14.9024 16.3166 14.9024 15.6834 15.2929 15.2929Z"
      ></path>
    </svg>
  )
}
export const PlayIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      aria-hidden="true"
      width="24"
      height="24"
      fill="#fff"
    >
      <path d="M6 19.8774V4.12264C6 2.4676 7.58699 1.45125 8.82368 2.31427L20.1119 10.1916C21.296 11.018 21.2961 12.982 20.1119 13.8084L8.82368 21.6857C7.58699 22.5487 6 21.5324 6 19.8774Z"></path>
    </svg>
  )
}
export const HomeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        clipRule="evenodd"
        d="M2.71344 9.19364L10.6711 2.4721C11.4164 1.84263 12.507 1.84263 13.2522 2.4721L21.2099 9.19364C22.6339 10.3964 21.7834 12.7215 19.9194 12.7215H19.1116V19.9994C19.1116 21.1039 18.2161 21.9994 17.1116 21.9994H6.65392C5.54935 21.9994 4.65392 21.1039 4.65392 19.9994V12.7215H4.004C2.14001 12.7215 1.28945 10.3964 2.71344 9.19364ZM10.8963 14.7833C10.344 14.7833 9.89633 15.231 9.89633 15.7833V21.9993H14.0271V15.7833C14.0271 15.231 13.5794 14.7833 13.0271 14.7833H10.8963Z"
        fill="url(#paint0_linear_3332_31467)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_31467"
          x1="11.9617"
          x2="11.9725"
          y1="1.36029"
          y2="22.0366"
        >
          <stop stopColor="#47D3FF" />
          <stop offset="1" stopColor="#E062FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const HotIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        d="M4.05945 12.8482C4.11088 12.4197 4.68745 12.2919 4.99385 12.5959C5.21773 12.818 5.49415 13.0671 5.82434 13.3212C6.15377 13.5748 6.6165 13.2976 6.60643 12.8821C6.54599 10.3874 6.94277 4.02789 12.3553 2.22435C12.7127 2.10524 13.0405 2.44188 12.9614 2.81027C11.6389 8.96931 15.0914 10.9631 16.8676 8.31945C17.0843 7.99689 17.5726 7.94101 17.768 8.27697C19.1431 10.6425 18.6751 12.7278 18.2711 13.7653C18.1394 14.1035 18.3565 14.4973 18.6975 14.3733C19.1022 14.2263 19.4461 13.9494 19.6767 13.7259C19.7898 13.6163 20 13.6887 20 13.8463C20 18.3493 16.4185 22 12 22C7.58149 22 4 18.3493 4 13.8459C4 13.5081 4.02022 13.1752 4.05945 12.8482Z"
        fill="url(#paint0_linear_3332_31504)"
      />
      <path
        d="M9.00019 18.1505C9.0003 18.1505 9.00038 18.1506 9.00038 18.1507C9.00048 20.2768 10.5676 22 12.5002 22C14.4328 22 16 20.2763 16 18.1505C16 17.2396 15.7123 16.4025 15.2314 15.7432C15.0464 15.4896 14.6826 15.542 14.4337 15.7332C13.3526 16.5636 12.1629 14.7994 12.6076 13.0147C12.712 12.5958 12.3945 12.1649 12.0056 12.3525C10.803 12.9327 9.00006 14.4239 9 18.1504C9 18.1505 9.00009 18.1505 9.00019 18.1505Z"
        fill="url(#paint1_linear_3332_31504)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_31504"
          x1="12"
          x2="12"
          y1="2"
          y2="22"
        >
          <stop stopColor="#FF4208" />
          <stop offset="1" stopColor="#F48605" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_31504"
          x1="12.5"
          x2="12.5"
          y1="12"
          y2="22"
        >
          <stop stopColor="#FFF5F5" />
          <stop offset="1" stopColor="#FFA34F" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const RecentlyPlayedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM13.25 6C13.25 5.30964 12.6904 4.75 12 4.75C11.3096 4.75 10.75 5.30964 10.75 6V12.0636C10.75 12.7145 11.0319 13.3336 11.5229 13.7609L15.1794 16.9429C15.7002 17.3961 16.4897 17.3414 16.9429 16.8206C17.3961 16.2998 17.3414 15.5103 16.8206 15.0571L13.25 11.9497V6Z"
        fill="url(#paint0_linear_3332_31476)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_31476"
          x1="12"
          x2="12"
          y1="2"
          y2="22"
        >
          <stop stopColor="#33E2E2" />
          <stop offset="1" stopColor="#2E4EF8" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const RecommendedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        d="M8.10302 13.9352C7.79181 13.9352 7.52336 13.7219 7.45984 13.4243C6.9401 10.9996 5.00219 9.10472 2.52296 8.59652C2.21855 8.53402 2.00043 8.27152 2.00043 7.96762C2.00043 7.66371 2.21855 7.40082 2.52296 7.33871C5.00258 6.83051 6.9405 4.93558 7.46024 2.51136C7.52416 2.21371 7.79261 2.00043 8.10382 2.00043C8.41502 2.00043 8.68347 2.21371 8.74699 2.51136C9.26673 4.93597 11.2046 6.8309 13.6839 7.3391C13.9883 7.4016 14.2064 7.6641 14.2064 7.96801C14.2064 8.27191 13.9883 8.5348 13.6839 8.59691C11.2042 9.10511 9.26633 11 8.74659 13.4243C8.68267 13.7219 8.41422 13.9352 8.10342 13.9352H8.10302Z"
        fill="url(#paint0_linear_3332_31495)"
      />
      <path
        d="M10.4919 22C10.1807 22 9.91227 21.7867 9.84875 21.4891C9.60387 20.3469 8.69064 19.4539 7.52253 19.2144C7.21812 19.1519 7 18.8894 7 18.5855C7 18.2816 7.21812 18.0187 7.52253 17.9566C8.69064 17.7172 9.60387 16.8246 9.84875 15.682C9.91267 15.3844 10.1811 15.1711 10.4919 15.1711C10.8031 15.1711 11.0716 15.3844 11.1351 15.682C11.38 16.8242 12.2932 17.7172 13.4613 17.9566C13.7657 18.0191 13.9839 18.2816 13.9839 18.5855C13.9839 18.8894 13.7657 19.1523 13.4613 19.2144C12.2932 19.4539 11.38 20.3469 11.1351 21.4891C11.0712 21.7867 10.8027 22 10.4919 22Z"
        fill="url(#paint1_linear_3332_31495)"
      />
      <path
        d="M18 17.9996C17.7112 17.9996 17.462 17.7972 17.403 17.5147C17.0957 16.049 15.9503 14.9037 14.485 14.5967C14.2024 14.5374 14 14.2883 14 13.9998C14 13.7114 14.2024 13.4618 14.485 13.4029C15.9507 13.0955 17.096 11.9502 17.403 10.485C17.4624 10.2024 17.7115 10 18 10C18.2888 10 18.538 10.2024 18.597 10.485C18.9043 11.9506 20.0497 13.0959 21.515 13.4033C21.7976 13.4626 22 13.7117 22 14.0002C22 14.2886 21.7976 14.5382 21.515 14.5971C20.0497 14.9041 18.904 16.0498 18.5966 17.515C18.5373 17.7976 18.2881 18 17.9996 18L18 17.9996Z"
        fill="url(#paint2_linear_3332_31495)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_31495"
          x1="8.10342"
          x2="8.10342"
          y1="2.00043"
          y2="13.9352"
        >
          <stop stopColor="#FFB31F" />
          <stop offset="1" stopColor="#FFDE9E" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_31495"
          x1="10.4919"
          x2="10.4919"
          y1="15.1711"
          y2="22"
        >
          <stop stopColor="#FFB31F" />
          <stop offset="1" stopColor="#FFDE9E" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_3332_31495"
          x1="18"
          x2="18"
          y1="10"
          y2="18"
        >
          <stop stopColor="#FFB31F" />
          <stop offset="1" stopColor="#FFDE9E" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const PlayerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <circle
        cx="7.49982"
        cy="9.5"
        fill="url(#paint0_linear_3332_33832)"
        r="3.5"
      />
      <circle
        cx="17.4998"
        cy="4.5"
        fill="url(#paint1_linear_3332_33832)"
        r="2.5"
      />
      <path
        d="M1.99982 19.5C1.99982 16.4624 4.46225 14 7.49982 14C10.5374 14 12.9998 16.4624 12.9998 19.5V20.6667C12.9998 21.403 12.4029 22 11.6665 22H3.33315C2.59677 22 1.99982 21.403 1.99982 20.6667V19.5Z"
        fill="url(#paint2_linear_3332_33832)"
      />
      <path
        d="M12.9998 12.5C12.9998 10.0147 15.0145 8 17.4998 8C19.9851 8 21.9998 10.0147 21.9998 12.5V13C21.9998 13.5523 21.5521 14 20.9998 14H13.9998C13.4475 14 12.9998 13.5523 12.9998 13V12.5Z"
        fill="url(#paint3_linear_3332_33832)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_33832"
          x1="7.49982"
          x2="7.49982"
          y1="6"
          y2="13"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CDCEF4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_33832"
          x1="17.4998"
          x2="17.4998"
          y1="2"
          y2="7"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CDCEF4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_3332_33832"
          x1="7.49982"
          x2="7.49982"
          y1="14"
          y2="22"
        >
          <stop stopColor="#7BA8FF" />
          <stop offset="1" stopColor="#6CEDFF" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_3332_33832"
          x1="17.4998"
          x2="17.4998"
          y1="8"
          y2="14"
        >
          <stop stopColor="#7BA8FF" />
          <stop offset="1" stopColor="#6CEDFF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export const TDIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        clipRule="evenodd"
        d="M10.5789 6.34211C10.5789 5.60088 11.2152 5 12 5C16.9706 5 21 8.80558 21 13.5C21 18.1944 16.9706 22 12 22C7.02944 22 3 18.1944 3 13.5C3 12.7588 3.63623 12.1579 4.42105 12.1579C5.20588 12.1579 5.84211 12.7588 5.84211 13.5C5.84211 16.712 8.59909 19.3158 12 19.3158C15.4009 19.3158 18.1579 16.712 18.1579 13.5C18.1579 10.288 15.4009 7.68421 12 7.68421C11.2152 7.68421 10.5789 7.08333 10.5789 6.34211Z"
        fill="url(#paint0_linear_3332_31523)"
        fillRule="evenodd"
      />
      <path
        d="M6.79781 8.60619C5.73406 7.79154 5.73406 6.20847 6.79781 5.39381L10.6647 2.4324C12.0221 1.39283 14 2.34532 14 4.03859L14 9.96141C14 11.6547 12.0221 12.6072 10.6647 11.5676L6.79781 8.60619Z"
        fill="url(#paint1_linear_3332_31523)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_31523"
          x1="12"
          x2="12"
          y1="6.34211"
          y2="20.6579"
        >
          <stop stopColor="#5FF5FF" />
          <stop offset="1" stopColor="#4CFFC9" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_31523"
          x1="4.70051"
          x2="14"
          y1="7"
          y2="7"
        >
          <stop stopColor="#C3F8FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export const ActionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        d="M6.41269 14.0708C6.79769 13.6858 7.42191 13.6858 7.80692 14.0708L9.86322 16.1271C10.2482 16.5121 10.2482 17.1364 9.86322 17.5214L6.08165 21.3029C5.69665 21.6879 5.07243 21.6879 4.68743 21.3029L2.63112 19.2466C2.24612 18.8616 2.24612 18.2374 2.63112 17.8524L6.41269 14.0708Z"
        fill="url(#paint0_linear_3332_33855)"
      />
      <path
        d="M16.5308 3.95264C16.7028 3.78065 16.9412 3.68915 17.1935 3.69827L19.621 3.3853C20.1465 3.4043 20.5879 3.84569 20.6069 4.37117L20.2357 6.74044C20.2448 6.99279 20.1533 7.23118 19.9813 7.40317L12.0715 15.313C11.7133 15.6712 11.1109 15.6494 10.7259 15.2644L8.66957 13.2081C8.28456 12.8231 8.26279 12.2206 8.62093 11.8625L16.5308 3.95264Z"
        fill="url(#paint1_linear_3332_33855)"
      />
      <path
        d="M14.1764 16.9898C14.5614 17.3748 14.5614 17.999 14.1764 18.384L12.9828 19.5777C12.5977 19.9627 11.9735 19.9627 11.5885 19.5777L4.35643 12.3456C3.97143 11.9606 3.97143 11.3364 4.35643 10.9514L5.5501 9.75768C5.93511 9.37268 6.55932 9.37268 6.94433 9.75768L14.1764 16.9898Z"
        fill="url(#paint2_linear_3332_33855)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_33855"
          x1="8.83507"
          x2="3.65928"
          y1="15.099"
          y2="20.2748"
        >
          <stop stopColor="#EF5600" />
          <stop offset="1" stopColor="#FFB763" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_33855"
          x1="20.1296"
          x2="9.69772"
          y1="3.80439"
          y2="14.2362"
        >
          <stop stopColor="white" stopOpacity="0.99" />
          <stop offset="1" stopColor="#E8F2F7" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_3332_33855"
          x1="10.5604"
          x2="7.97248"
          y1="13.3737"
          y2="15.9616"
        >
          <stop stopColor="#FFFB93" />
          <stop offset="1" stopColor="#F1CF78" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export const AdventureIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        d="M11.9504 21.9506C6.42759 21.9506 1.95044 17.4735 1.95044 11.9506C1.95044 6.42777 6.42759 1.95062 11.9504 1.95062C17.4733 1.95062 21.9504 6.42778 21.9504 11.9506C21.9504 17.4735 17.4733 21.9506 11.9504 21.9506Z"
        fill="url(#paint0_linear_3332_38927)"
      />
      <path
        clipRule="evenodd"
        d="M4.06189 11C4.51313 7.38136 7.38118 4.51326 10.9998 4.06194V5.00002C10.9998 5.5523 11.4475 6.00002 11.9998 6.00002C12.5521 6.00002 12.9998 5.5523 12.9998 5.00002V4.06189C16.6186 4.51306 19.4868 7.38124 19.9381 11H18.9998C18.4475 11 17.9998 11.4477 17.9998 12C17.9998 12.5523 18.4475 13 18.9998 13H19.9381C19.4868 16.6188 16.6186 19.487 12.9998 19.9381V19C12.9998 18.4477 12.5521 18 11.9998 18C11.4475 18 10.9998 18.4477 10.9998 19V19.9381C7.38118 19.4868 4.51313 16.6187 4.06189 13H4.99981C5.5521 13 5.99981 12.5523 5.99981 12C5.99981 11.4477 5.5521 11 4.99981 11H4.06189Z"
        fill="url(#paint1_linear_3332_38927)"
        fillRule="evenodd"
      />
      <path
        d="M13.6703 14.1286L16.4959 8.30004C16.7028 7.87322 16.2547 7.42509 15.8279 7.63201L9.99935 10.4576C9.89115 10.5101 9.80549 10.5998 9.75804 10.7102L7.49754 15.9736C7.31928 16.3887 7.73921 16.8086 8.15428 16.6304L13.4177 14.3699C13.5282 14.3224 13.6178 14.2368 13.6703 14.1286Z"
        fill="url(#paint2_linear_3332_38927)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_38927"
          x1="17.7902"
          x2="6.29016"
          y1="3.79035"
          y2="20.7903"
        >
          <stop stopColor="#F3AB69" />
          <stop offset="0.979167" stopColor="#FF5454" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_38927"
          x1="17.2556"
          x2="6.90557"
          y1="4.65575"
          y2="19.9557"
        >
          <stop stopColor="#4F2924" />
          <stop offset="1" stopColor="#6A1136" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_3332_38927"
          x1="17.8316"
          x2="6.29607"
          y1="12.0641"
          y2="12.0641"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#ECF7FF" stopOpacity="0.84" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export const ArcadeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        d="M5.16806 18.7994C4.6663 18.7994 4.1943 18.6902 3.76633 18.469C2.72043 17.9303 2.09925 16.8013 2.0161 15.2928C1.88322 12.8648 2.57166 10.4928 4.12298 8.04397C5.31765 6.15629 6.68636 5.63317 8.85315 6.24067C9.86807 6.52582 10.9506 6.80018 11.988 6.76698C12.9165 6.75059 13.9208 6.58341 15.1461 6.24067C17.3128 5.63237 18.6824 6.15709 19.877 8.04437C21.4284 10.494 22.1168 12.8648 21.9839 15.2932C21.9004 16.8025 21.2788 17.9303 20.2337 18.4694C19.1487 19.0301 17.7763 18.8746 16.3705 18.0299C15.3287 17.4052 14.3419 16.7477 13.5124 16.1862C12.623 15.5839 11.3786 15.5839 10.488 16.1862C9.65938 16.7477 8.67136 17.4056 7.62995 18.0299C6.77766 18.541 5.93882 18.7994 5.16846 18.7994H5.16806Z"
        fill="url(#paint0_linear_3332_36306)"
      />
      <path
        clipRule="evenodd"
        d="M6.64001 13.589C6.64001 14.1087 7.0698 14.5309 7.59995 14.5309C8.12964 14.5309 8.55988 14.1092 8.55988 13.589V13.1179H9.04006C9.56976 13.1179 9.99999 12.6957 9.99999 12.176C9.99999 11.6562 9.57021 11.2341 9.04006 11.2341H8.55988V10.7629C8.55988 10.2432 8.12964 9.82104 7.59995 9.82104C7.07025 9.82104 6.64001 10.2427 6.64001 10.7629V11.2341H6.15982C5.63013 11.2341 5.19989 11.6562 5.19989 12.176C5.19989 12.6957 5.62968 13.1179 6.15982 13.1179H6.64001V13.589Z"
        fill="url(#paint1_linear_3332_36306)"
        fillRule="evenodd"
      />
      <path
        d="M16.8002 14.0001C17.9048 14.0001 18.8003 13.1046 18.8003 12C18.8003 10.8954 17.9048 10 16.8002 10C15.6956 10 14.8002 10.8954 14.8002 12C14.8002 13.1046 15.6956 14.0001 16.8002 14.0001Z"
        fill="url(#paint2_linear_3332_36306)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_36306"
          x1="12"
          x2="12"
          y1="6"
          y2="18.8003"
        >
          <stop stopColor="#ED7DFF" />
          <stop offset="1" stopColor="#9D00FD" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_36306"
          x1="7.59994"
          x2="7.59994"
          y1="9.82104"
          y2="14.5309"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D3D0F1" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_3332_36306"
          x1="16.8002"
          x2="16.8002"
          y1="10"
          y2="14.0001"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D3D0F1" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export const BabyHazelIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        d="M8.10302 13.9352C7.79181 13.9352 7.52336 13.7219 7.45984 13.4243C6.9401 10.9996 5.00219 9.10472 2.52296 8.59652C2.21855 8.53402 2.00043 8.27152 2.00043 7.96762C2.00043 7.66371 2.21855 7.40082 2.52296 7.33871C5.00258 6.83051 6.9405 4.93558 7.46024 2.51136C7.52416 2.21371 7.79261 2.00043 8.10382 2.00043C8.41502 2.00043 8.68347 2.21371 8.74699 2.51136C9.26673 4.93597 11.2046 6.8309 13.6839 7.3391C13.9883 7.4016 14.2064 7.6641 14.2064 7.96801C14.2064 8.27191 13.9883 8.5348 13.6839 8.59691C11.2042 9.10511 9.26633 11 8.74659 13.4243C8.68267 13.7219 8.41422 13.9352 8.10342 13.9352H8.10302Z"
        fill="url(#paint0_linear_3332_31495)"
      />
      <path
        d="M10.4919 22C10.1807 22 9.91227 21.7867 9.84875 21.4891C9.60387 20.3469 8.69064 19.4539 7.52253 19.2144C7.21812 19.1519 7 18.8894 7 18.5855C7 18.2816 7.21812 18.0187 7.52253 17.9566C8.69064 17.7172 9.60387 16.8246 9.84875 15.682C9.91267 15.3844 10.1811 15.1711 10.4919 15.1711C10.8031 15.1711 11.0716 15.3844 11.1351 15.682C11.38 16.8242 12.2932 17.7172 13.4613 17.9566C13.7657 18.0191 13.9839 18.2816 13.9839 18.5855C13.9839 18.8894 13.7657 19.1523 13.4613 19.2144C12.2932 19.4539 11.38 20.3469 11.1351 21.4891C11.0712 21.7867 10.8027 22 10.4919 22Z"
        fill="url(#paint1_linear_3332_31495)"
      />
      <path
        d="M18 17.9996C17.7112 17.9996 17.462 17.7972 17.403 17.5147C17.0957 16.049 15.9503 14.9037 14.485 14.5967C14.2024 14.5374 14 14.2883 14 13.9998C14 13.7114 14.2024 13.4618 14.485 13.4029C15.9507 13.0955 17.096 11.9502 17.403 10.485C17.4624 10.2024 17.7115 10 18 10C18.2888 10 18.538 10.2024 18.597 10.485C18.9043 11.9506 20.0497 13.0959 21.515 13.4033C21.7976 13.4626 22 13.7117 22 14.0002C22 14.2886 21.7976 14.5382 21.515 14.5971C20.0497 14.9041 18.904 16.0498 18.5966 17.515C18.5373 17.7976 18.2881 18 17.9996 18L18 17.9996Z"
        fill="url(#paint2_linear_3332_31495)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_31495"
          x1="8.10342"
          x2="8.10342"
          y1="2.00043"
          y2="13.9352"
        >
          <stop stopColor="#FFB31F" />
          <stop offset="1" stopColor="#FFDE9E" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_31495"
          x1="10.4919"
          x2="10.4919"
          y1="15.1711"
          y2="22"
        >
          <stop stopColor="#FFB31F" />
          <stop offset="1" stopColor="#FFDE9E" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_3332_31495"
          x1="18"
          x2="18"
          y1="10"
          y2="18"
        >
          <stop stopColor="#FFB31F" />
          <stop offset="1" stopColor="#FFDE9E" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export const BejeweledIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        d="M8.10302 13.9352C7.79181 13.9352 7.52336 13.7219 7.45984 13.4243C6.9401 10.9996 5.00219 9.10472 2.52296 8.59652C2.21855 8.53402 2.00043 8.27152 2.00043 7.96762C2.00043 7.66371 2.21855 7.40082 2.52296 7.33871C5.00258 6.83051 6.9405 4.93558 7.46024 2.51136C7.52416 2.21371 7.79261 2.00043 8.10382 2.00043C8.41502 2.00043 8.68347 2.21371 8.74699 2.51136C9.26673 4.93597 11.2046 6.8309 13.6839 7.3391C13.9883 7.4016 14.2064 7.6641 14.2064 7.96801C14.2064 8.27191 13.9883 8.5348 13.6839 8.59691C11.2042 9.10511 9.26633 11 8.74659 13.4243C8.68267 13.7219 8.41422 13.9352 8.10342 13.9352H8.10302Z"
        fill="url(#paint0_linear_3332_31495)"
      />
      <path
        d="M10.4919 22C10.1807 22 9.91227 21.7867 9.84875 21.4891C9.60387 20.3469 8.69064 19.4539 7.52253 19.2144C7.21812 19.1519 7 18.8894 7 18.5855C7 18.2816 7.21812 18.0187 7.52253 17.9566C8.69064 17.7172 9.60387 16.8246 9.84875 15.682C9.91267 15.3844 10.1811 15.1711 10.4919 15.1711C10.8031 15.1711 11.0716 15.3844 11.1351 15.682C11.38 16.8242 12.2932 17.7172 13.4613 17.9566C13.7657 18.0191 13.9839 18.2816 13.9839 18.5855C13.9839 18.8894 13.7657 19.1523 13.4613 19.2144C12.2932 19.4539 11.38 20.3469 11.1351 21.4891C11.0712 21.7867 10.8027 22 10.4919 22Z"
        fill="url(#paint1_linear_3332_31495)"
      />
      <path
        d="M18 17.9996C17.7112 17.9996 17.462 17.7972 17.403 17.5147C17.0957 16.049 15.9503 14.9037 14.485 14.5967C14.2024 14.5374 14 14.2883 14 13.9998C14 13.7114 14.2024 13.4618 14.485 13.4029C15.9507 13.0955 17.096 11.9502 17.403 10.485C17.4624 10.2024 17.7115 10 18 10C18.2888 10 18.538 10.2024 18.597 10.485C18.9043 11.9506 20.0497 13.0959 21.515 13.4033C21.7976 13.4626 22 13.7117 22 14.0002C22 14.2886 21.7976 14.5382 21.515 14.5971C20.0497 14.9041 18.904 16.0498 18.5966 17.515C18.5373 17.7976 18.2881 18 17.9996 18L18 17.9996Z"
        fill="url(#paint2_linear_3332_31495)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_31495"
          x1="8.10342"
          x2="8.10342"
          y1="2.00043"
          y2="13.9352"
        >
          <stop stopColor="#FFB31F" />
          <stop offset="1" stopColor="#FFDE9E" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_31495"
          x1="10.4919"
          x2="10.4919"
          y1="15.1711"
          y2="22"
        >
          <stop stopColor="#FFB31F" />
          <stop offset="1" stopColor="#FFDE9E" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_3332_31495"
          x1="18"
          x2="18"
          y1="10"
          y2="18"
        >
          <stop stopColor="#FFB31F" />
          <stop offset="1" stopColor="#FFDE9E" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export const BoysIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        d="M8.10302 13.9352C7.79181 13.9352 7.52336 13.7219 7.45984 13.4243C6.9401 10.9996 5.00219 9.10472 2.52296 8.59652C2.21855 8.53402 2.00043 8.27152 2.00043 7.96762C2.00043 7.66371 2.21855 7.40082 2.52296 7.33871C5.00258 6.83051 6.9405 4.93558 7.46024 2.51136C7.52416 2.21371 7.79261 2.00043 8.10382 2.00043C8.41502 2.00043 8.68347 2.21371 8.74699 2.51136C9.26673 4.93597 11.2046 6.8309 13.6839 7.3391C13.9883 7.4016 14.2064 7.6641 14.2064 7.96801C14.2064 8.27191 13.9883 8.5348 13.6839 8.59691C11.2042 9.10511 9.26633 11 8.74659 13.4243C8.68267 13.7219 8.41422 13.9352 8.10342 13.9352H8.10302Z"
        fill="url(#paint0_linear_3332_31495)"
      />
      <path
        d="M10.4919 22C10.1807 22 9.91227 21.7867 9.84875 21.4891C9.60387 20.3469 8.69064 19.4539 7.52253 19.2144C7.21812 19.1519 7 18.8894 7 18.5855C7 18.2816 7.21812 18.0187 7.52253 17.9566C8.69064 17.7172 9.60387 16.8246 9.84875 15.682C9.91267 15.3844 10.1811 15.1711 10.4919 15.1711C10.8031 15.1711 11.0716 15.3844 11.1351 15.682C11.38 16.8242 12.2932 17.7172 13.4613 17.9566C13.7657 18.0191 13.9839 18.2816 13.9839 18.5855C13.9839 18.8894 13.7657 19.1523 13.4613 19.2144C12.2932 19.4539 11.38 20.3469 11.1351 21.4891C11.0712 21.7867 10.8027 22 10.4919 22Z"
        fill="url(#paint1_linear_3332_31495)"
      />
      <path
        d="M18 17.9996C17.7112 17.9996 17.462 17.7972 17.403 17.5147C17.0957 16.049 15.9503 14.9037 14.485 14.5967C14.2024 14.5374 14 14.2883 14 13.9998C14 13.7114 14.2024 13.4618 14.485 13.4029C15.9507 13.0955 17.096 11.9502 17.403 10.485C17.4624 10.2024 17.7115 10 18 10C18.2888 10 18.538 10.2024 18.597 10.485C18.9043 11.9506 20.0497 13.0959 21.515 13.4033C21.7976 13.4626 22 13.7117 22 14.0002C22 14.2886 21.7976 14.5382 21.515 14.5971C20.0497 14.9041 18.904 16.0498 18.5966 17.515C18.5373 17.7976 18.2881 18 17.9996 18L18 17.9996Z"
        fill="url(#paint2_linear_3332_31495)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_31495"
          x1="8.10342"
          x2="8.10342"
          y1="2.00043"
          y2="13.9352"
        >
          <stop stopColor="#FFB31F" />
          <stop offset="1" stopColor="#FFDE9E" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_31495"
          x1="10.4919"
          x2="10.4919"
          y1="15.1711"
          y2="22"
        >
          <stop stopColor="#FFB31F" />
          <stop offset="1" stopColor="#FFDE9E" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_3332_31495"
          x1="18"
          x2="18"
          y1="10"
          y2="18"
        >
          <stop stopColor="#FFB31F" />
          <stop offset="1" stopColor="#FFDE9E" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export const GirlsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        clipRule="evenodd"
        d="M6.61787 2H6.86705C6.88941 2 6.91158 2.00073 6.93355 2.00216C6.95546 2.00073 6.97756 2 6.99983 2H9.33317C9.97347 2 10.5693 2.16546 11.0753 2.45004C11.6222 2.75762 12.3776 2.75774 12.9244 2.45015C13.4304 2.16557 14.0262 2 14.6665 2H16.9998C17.0221 2 17.0442 2.00073 17.0661 2.00216C17.0881 2.00073 17.1103 2 17.1326 2H17.3818C17.7606 2 18.1068 2.214 18.2762 2.55279L19.2762 4.55279C19.6087 5.21769 19.1252 6 18.3818 6H17.3826C16.9671 6 16.6051 5.74505 16.4556 5.37535C16.4546 5.37804 16.4536 5.38073 16.4525 5.38341C15.9522 6.70465 15.3832 8.4057 15.2132 9.80265C15.1707 10.1518 14.9777 10.4709 14.6692 10.6398L14.2354 10.8772C14.0882 10.9578 13.9231 11 13.7553 11H10.2006C10.026 11 9.85451 10.9543 9.70308 10.8675L9.31144 10.6428C9.01271 10.4715 8.82724 10.1587 8.78515 9.81693C8.609 8.38667 8.00345 6.60164 7.54266 5.37891C7.39222 5.74669 7.03125 6 6.61705 6H5.61787C4.87449 6 4.39099 5.21769 4.72344 4.55279L5.72344 2.55279C5.89283 2.214 6.2391 2 6.61787 2Z"
        fill="url(#paint0_linear_3332_36361)"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16.6252 21.2957C16.6252 21.2957 16.6251 21.2957 16.6251 21.2957L16.6251 21.2957C16.6251 21.2957 16.6251 21.2957 16.6251 21.2957C15.3128 21.6918 13.7396 22 11.9996 22C10.2598 22 8.6863 21.6918 7.37445 21.2958C7.37435 21.2958 7.37424 21.2958 7.37422 21.2959C7.37419 21.296 7.37408 21.2961 7.37398 21.296C5.8096 20.8235 4.75967 20.3194 3.83143 19.7661C3.36276 19.4867 3.23376 18.8726 3.52557 18.4115L8.77276 10.1215C8.95612 9.83186 9.2751 9.6563 9.61795 9.65637L10.8531 9.65664C10.8532 9.65664 10.8534 9.65657 10.8535 9.65645C10.8537 9.65632 10.8538 9.65625 10.854 9.65625H11.9995C11.9996 9.65625 11.9997 9.65616 11.9997 9.65605C11.9997 9.65594 11.9998 9.65585 11.9999 9.65585H13.1453C13.1455 9.65585 13.1457 9.65592 13.1458 9.65605C13.146 9.65618 13.1461 9.65625 13.1463 9.65625H14.3789C14.7231 9.65625 15.0412 9.83002 15.2248 10.1211C16.2871 11.8046 19.2618 16.4992 20.4749 18.4134C20.767 18.8744 20.6364 19.486 20.1665 19.7638C19.1937 20.3388 18.1145 20.8459 16.6252 21.2957C16.6252 21.2957 16.6252 21.2957 16.6252 21.2957Z"
        fill="url(#paint1_linear_3332_36361)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_36361"
          x1="11.9998"
          x2="11.9998"
          y1="2"
          y2="5"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FCE0FE" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_36361"
          x1="12.0001"
          x2="12.0001"
          y1="9.65585"
          y2="22"
        >
          <stop stopColor="#FF899F" />
          <stop offset="1" stopColor="#C13DAC" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export const ClickerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        clipRule="evenodd"
        d="M5.92467 15.6355L4.72269 14.4325C3.49069 13.2015 2.87569 12.5865 3.02069 11.9245C3.16665 11.2627 3.9832 10.9617 5.61521 10.36L5.61669 10.3595L11.0667 8.3535C14.3267 7.1515 15.9567 6.5515 16.7967 7.3915C17.6355 8.23027 17.0365 9.85574 15.839 13.1052L15.8337 13.1195L13.8267 18.5695L13.8261 18.571C13.2245 20.203 12.9235 21.0195 12.2617 21.1655C11.5997 21.3105 10.9837 20.6955 9.75369 19.4635L8.55069 18.2615L8.55043 18.2612L6.61266 20.1995L6.60807 20.2041C6.20313 20.608 5.9998 20.8109 5.77266 20.9055C5.46976 21.0307 5.12956 21.0307 4.82665 20.9055C4.59951 20.8109 4.39619 20.608 3.99124 20.2041L3.98665 20.1995C3.57865 19.7915 3.37466 19.5875 3.28066 19.3595C3.15466 19.0565 3.15466 18.7155 3.28066 18.4135C3.37466 18.1855 3.57866 17.9815 3.98665 17.5735L5.92467 15.6355Z"
        fill="url(#paint0_linear_3332_36271)"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M13.9998 5C14.5521 5 14.9998 4.55228 14.9998 4L14.9998 3C14.9998 2.44772 14.5521 2 13.9998 2C13.4475 2 12.9998 2.44772 12.9998 3V4C12.9998 4.55228 13.4475 5 13.9998 5ZM20.1693 2.63389C20.5194 2.2838 21.087 2.2838 21.437 2.63389C21.7871 2.98397 21.7871 3.55158 21.437 3.90166L19.4606 5.87816C19.1105 6.22825 18.5429 6.22825 18.1928 5.87816C17.8427 5.52807 17.8427 4.96047 18.1928 4.61038L20.1693 2.63389ZM20.9998 9C21.5521 9 21.9998 9.44772 21.9998 10C21.9998 10.5523 21.5521 11 20.9998 11H19.9998C19.4475 11 18.9998 10.5523 18.9998 10C18.9998 9.44772 19.4475 9 19.9998 9H20.9998Z"
        fill="url(#paint1_linear_3332_36271)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_36271"
          x1="17.1877"
          x2="2.99982"
          y1="14.0932"
          y2="14.0932"
        >
          <stop stopColor="#FFD873" />
          <stop offset="1" stopColor="#E45E12" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_36271"
          x1="17.4998"
          x2="17.4998"
          y1="2"
          y2="11"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FAE0CD" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export const HypercasualIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <rect
        fill="#5272AF"
        height="10.5338"
        rx="1"
        transform="rotate(15 13.7264 7.67291)"
        width="2"
        x="13.7264"
        y="7.67291"
      />
      <circle cx="15.21" cy="6" fill="url(#paint0_linear_3332_36250)" r="4" />
      <path
        d="M4 17C4 16.4477 4.44772 16 5 16H19C19.5523 16 20 16.4477 20 17V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V17Z"
        fill="url(#paint1_linear_3332_36250)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_36250"
          x1="15.21"
          x2="15.21"
          y1="2"
          y2="10"
        >
          <stop stopColor="#A6EAFF" />
          <stop offset="1" stopColor="#2D68FF" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_36250"
          x1="12"
          x2="12"
          y1="16"
          y2="22"
        >
          <stop stopColor="#E1DCFF" />
          <stop offset="1" stopColor="#A8E5FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export const MultiplayerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        d="M11.9998 13.0045C10.2931 13.0045 8.89673 11.608 8.89673 9.90138V8.78859C8.89673 7.08192 10.2931 5.68549 11.9998 5.68549C13.7064 5.68549 15.1028 7.08192 15.1028 8.78859V9.90138C15.1028 11.6084 13.7064 13.0045 11.9998 13.0045Z"
        fill="url(#paint0_linear_3332_36620)"
      />
      <path
        d="M13.7473 13.4309C13.2224 13.7027 12.629 13.859 11.9998 13.859C11.3706 13.859 10.7775 13.7027 10.2523 13.4309C7.90615 13.7812 6.09161 15.8168 6.09161 18.2562V21.466C6.09161 21.76 6.33202 22 6.62558 22H17.3732C17.6671 22 17.9071 21.7596 17.9071 21.466V18.2562C17.9075 15.8168 16.093 13.7808 13.7469 13.4309H13.7473Z"
        fill="url(#paint1_linear_3332_36620)"
      />
      <path
        d="M17.8394 9.74545C17.3145 10.0172 16.721 10.1735 16.0919 10.1735H16.0895C16.024 11.1538 15.6154 12.0405 14.9795 12.7137C17.2804 13.5317 18.9081 15.747 18.9081 18.2562V18.3145H21.4661C21.76 18.3145 22.0004 18.0741 22.0004 17.7801V14.5707C22.0004 12.1313 20.1855 10.0954 17.8398 9.74545H17.8394Z"
        fill="url(#paint2_linear_3332_36620)"
      />
      <path
        d="M16.0918 2C14.4824 2 13.1503 3.24212 13.0035 4.81509C14.7811 5.26457 16.103 6.87325 16.103 8.78859V9.3186C17.8044 9.31265 19.1949 7.91899 19.1949 6.21629V5.1035C19.1949 3.39644 17.7985 2 16.0918 2Z"
        fill="url(#paint3_linear_3332_36620)"
      />
      <path
        d="M5.09233 18.2562C5.09233 15.747 6.71923 13.5313 9.02053 12.7137C8.38461 12.0404 7.9756 11.1538 7.91054 10.1735H7.90816C7.27898 10.1735 6.68591 10.0172 6.16067 9.74545C3.81454 10.0954 2 12.1313 2 14.5707V17.7805C2 18.0745 2.2404 18.3149 2.53436 18.3149H5.09233V18.2562Z"
        fill="url(#paint4_linear_3332_36620)"
      />
      <path
        d="M7.89669 9.31821V8.78819C7.89669 6.87246 9.21812 5.26417 10.9962 4.81469C10.8494 3.24212 9.51723 2 7.90779 2C6.20116 2 4.80475 3.39644 4.80475 5.10311V6.21589C4.80475 7.91899 6.19521 9.31225 7.89669 9.31821Z"
        fill="url(#paint5_linear_3332_36620)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_36620"
          x1="11.9998"
          x2="11.9998"
          y1="5.68549"
          y2="13.0045"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CAB6EB" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_36620"
          x1="11.9994"
          x2="11.9994"
          y1="13.4309"
          y2="22"
        >
          <stop stopColor="#CD7BFF" />
          <stop offset="1" stopColor="#7A29FF" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_3332_36620"
          x1="18.49"
          x2="18.49"
          y1="9.74545"
          y2="18.3145"
        >
          <stop stopColor="#CD7BFF" />
          <stop offset="1" stopColor="#7A29FF" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_3332_36620"
          x1="16.0992"
          x2="16.0992"
          y1="2"
          y2="9.3186"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CAB6EB" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint4_linear_3332_36620"
          x1="5.51026"
          x2="5.51026"
          y1="9.74545"
          y2="18.3149"
        >
          <stop stopColor="#CD7BFF" />
          <stop offset="1" stopColor="#7A29FF" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint5_linear_3332_36620"
          x1="7.90045"
          x2="7.90045"
          y1="2"
          y2="9.31821"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CAB6EB" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export const PuzzleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        d="M16.131 22H12.7355C12.2336 22 11.9195 21.4555 12.1711 21.0207C12.3437 20.7223 12.4351 20.3844 12.4351 20.0434C12.4351 18.9125 11.4613 17.968 10.2546 18.0992C9.40807 18.1922 8.69125 18.868 8.55023 19.7066C8.47288 20.1672 8.55453 20.6215 8.78578 21.0207C9.03735 21.4559 8.72289 22 8.22131 22H3.95671C2.87776 22 2 21.1223 2 20.0434V15.7785C2 15.2762 2.54572 14.9629 2.97933 15.214C3.76217 15.6676 4.81533 15.4945 5.41457 14.8265C5.79037 14.4074 5.96303 13.864 5.90131 13.2976C5.80834 12.4511 5.13253 11.7343 4.29422 11.5937C3.83288 11.5156 3.37895 11.5976 2.97972 11.8289C2.5426 12.0812 2 11.7644 2 11.2648V7.86949C2 6.79058 2.87776 5.91284 3.95671 5.91284H7.28417C7.24003 5.69878 7.21737 5.48003 7.21737 5.2605C7.21737 4.33511 7.6127 3.44995 8.30217 2.83159C8.99165 2.21401 9.91903 1.91752 10.8441 2.01986C12.2816 2.17768 13.4531 3.28276 13.693 4.70737C13.7613 5.11597 13.7547 5.52183 13.6742 5.91284H16.1306C17.2095 5.91284 18.0873 6.79058 18.0873 7.86949V10.3261C18.4791 10.2461 18.8842 10.2394 19.2928 10.3078C20.7174 10.5468 21.8226 11.7183 21.9804 13.1558C22.0819 14.0824 21.7858 15.0086 21.1682 15.6976C20.4038 16.5508 19.2041 16.9469 18.0873 16.716V20.0434C18.0873 21.1223 17.2095 22 16.1306 22H16.131Z"
        fill="url(#paint0_linear_3332_36660)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_36660"
          x1="12"
          x2="12"
          y1="2"
          y2="22"
        >
          <stop stopColor="#FFCA62" />
          <stop offset="1" stopColor="#FF5C38" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export const RacingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        d="M10.6246 4.75365C10.6246 5.89432 11.6317 6.8188 12.8744 6.8188C14.1171 6.8188 15.1242 5.89432 15.1242 4.75365V4.06514C15.1242 2.92447 14.1171 2 12.8744 2C11.6317 2 10.6246 2.92447 10.6246 4.06514V4.75365Z"
        fill="url(#paint0_linear_3332_38794)"
      />
      <path
        d="M11.6273 17.767C11.1958 18.4812 10.1904 18.7974 9.31239 18.4943L5.71917 17.2046C5.15758 17.0365 4.86316 16.5146 5.06222 16.0392C5.06486 16.0329 5.06794 16.0262 5.07057 16.0199C5.27974 15.553 5.89318 15.315 6.44775 15.4853L9.22055 16.5064C9.51936 16.5912 9.84542 16.4703 9.97417 16.2271L10.7963 14.4557C10.9304 14.208 10.9998 13.939 10.9998 13.6667V10.2914C10.9998 10.1102 10.8267 9.9633 10.6127 9.9633C10.5916 9.9633 10.5705 9.96479 10.5498 9.96777L9.58264 10.1043C9.38446 10.1322 9.24384 10.2836 9.25922 10.4536L9.49343 12.5448C9.53254 12.9808 9.14672 13.3606 8.63215 13.3937C8.56931 13.3978 8.50604 13.3963 8.44364 13.3896C7.99146 13.3301 7.65003 13.0109 7.62454 12.6241L7.34199 9.97112C7.2853 9.37595 7.72253 8.82654 8.39574 8.64761C8.97403 8.49399 9.67536 8.29275 10.146 8.15549C10.5006 8.05245 10.8732 8 11.2481 8H14.0886C14.5126 8 14.9116 8.16925 15.1647 8.45716L16.5599 10.2698L18.3185 8.29535C18.6534 7.95053 19.2549 7.90068 19.6623 8.18413C19.6843 8.19938 19.7049 8.21538 19.7251 8.23174C20.0674 8.53602 20.0929 9.00695 19.7853 9.33652C19.0686 10.1396 17.4265 12.0884 17.4265 12.0884C17.1268 12.46 16.5278 12.5553 16.0893 12.3016C16.0124 12.2573 15.9438 12.2034 15.8849 12.1424C15.881 12.139 15.3001 11.4694 14.7077 10.7805C14.6479 10.7106 14.5324 10.6954 14.4502 10.7459C14.4023 10.7753 14.3742 10.8226 14.3742 10.8724V13.361C14.3742 13.6288 14.5108 13.8832 14.749 14.0592L16.6091 15.4176C16.7647 15.5307 16.8803 15.678 16.9426 15.8428L18.8177 20.7183C19.0326 21.2119 18.7338 21.7591 18.1506 21.941C17.5675 22.1229 16.9211 21.87 16.7062 21.3764C16.6966 21.354 16.6878 21.3313 16.6803 21.3087L14.9024 16.6838L13.0836 15.338L11.6269 17.767H11.6273Z"
        fill="url(#paint1_linear_3332_38794)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_38794"
          x1="12.8744"
          x2="12.8744"
          y1="2"
          y2="6.8188"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_38794"
          x1="12.5"
          x2="12.5"
          y1="8"
          y2="22"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#7EC9FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export const ShootingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        d="M10.8511 12.6402L9.60532 10.3403C9.4738 10.0975 9.17034 10.0073 8.92753 10.1388C8.68472 10.2704 8.5945 10.5738 8.72603 10.8166L9.97179 13.1165C10.1033 13.3593 10.4068 13.4496 10.6496 13.318C10.8924 13.1865 10.9826 12.8831 10.8511 12.6402Z"
        fill="white"
      />
      <path
        clipRule="evenodd"
        d="M19.5104 6H5H3C2.44772 6 2 6.44772 2 7V8C2 8.55228 2.44772 9 3 9H3.1901C3.42844 9 3.63365 9.16823 3.68039 9.40194L3.91709 10.5855C3.93279 10.6639 3.92942 10.745 3.90726 10.8219L2.04938 17.2715C2.0166 17.4293 2 17.5911 2 17.7533C2 19.0884 3.11701 20.1749 4.48963 20.1749H8.55394C8.83008 20.1749 9.05394 19.951 9.05394 19.6749V19.4641C9.05394 19.188 8.83008 18.9641 8.55394 18.9641H8.43547C8.12041 18.9641 7.88387 18.6762 7.94497 18.3672L8.35358 16.2999C8.39988 16.0657 8.60533 15.8969 8.84409 15.8969H11.5436C13.2597 15.8969 14.6556 14.5392 14.6556 12.87V11.5C14.6556 11.2239 14.8795 11 15.1556 11H21C21.5523 11 22 10.5523 22 10V7C22 6.44772 21.5523 6 21 6H20.7552V5.62241C20.7552 5.27866 20.4765 5 20.1328 5C19.789 5 19.5104 5.27866 19.5104 5.62241V6ZM9.3215 11.403C9.3678 11.1688 9.57325 11 9.81201 11H12.9108C13.1869 11 13.4108 11.2239 13.4108 11.5V12.87C13.4108 13.8713 12.573 14.6861 11.5436 14.6861H9.28107C8.96601 14.6861 8.72947 14.3982 8.79056 14.0891L9.3215 11.403Z"
        fill="url(#paint0_linear_3332_36536)"
        fillRule="evenodd"
      />
      <path
        d="M5 11H7.26301C7.89966 11 8.37421 11.587 8.24081 12.2095L6.82581 18.8129H4.14209C3.48057 18.8129 3.00135 18.1821 3.1787 17.5448L5 11Z"
        fill="url(#paint1_linear_3332_36536)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_36536"
          x1="12"
          x2="12"
          y1="6.21075"
          y2="20.1749"
        >
          <stop stopColor="#FFDF8E" />
          <stop offset="1" stopColor="#FF800B" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_36536"
          x1="5.6629"
          x2="5.6629"
          y1="11"
          y2="18.8129"
        >
          <stop stopColor="#693F00" />
          <stop offset="1" stopColor="#7E4C00" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export const SportsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        d="M19.5312 2.00039H17.6484C16.932 2.00039 16.223 2.04805 15.5234 2.14297C15.5234 2.28398 15.409 2.39844 15.268 2.39844H14.1227C13.248 2.6 12.3914 2.87734 11.5574 3.23008C8.10391 4.69102 4.9332 7.53047 3.23008 11.5574C2.87734 12.3914 2.6 13.2477 2.39844 14.1219V15.2676C2.39844 15.409 2.28398 15.5234 2.14258 15.5234C2.04766 16.223 2 16.9324 2 17.6488V19.5316C2 20.893 3.10742 22.0004 4.46875 22.0004H6.35156C7.06836 22.0004 7.77773 21.9527 8.47773 21.8578C8.47773 21.7168 8.59219 21.602 8.73359 21.602H9.87812C10.7523 21.4004 11.6086 21.123 12.4426 20.7703C15.8957 19.3098 19.0664 16.4703 20.7699 12.443C21.1227 11.609 21.4004 10.7523 21.6016 9.87773V8.73242C21.6016 8.59101 21.716 8.47695 21.8574 8.47695C21.952 7.77734 22 7.06797 22 6.35156V4.46875C22 3.10742 20.8926 2 19.5312 2V2.00039Z"
        fill="url(#paint0_linear_3332_38787)"
      />
      <path
        d="M8.34923 14.8244L7.82228 15.3513C7.59337 15.5802 7.59337 15.9509 7.82228 16.1798C8.05118 16.4087 8.42228 16.4087 8.65079 16.1798L9.17775 15.6529L9.7047 16.1798C9.93361 16.4087 10.3047 16.4087 10.5332 16.1798C10.7621 15.9509 10.7621 15.5802 10.5332 15.3513L10.0063 14.8244L11.0606 13.7701L11.5875 14.297C11.8164 14.5259 12.1871 14.5259 12.416 14.297C12.6449 14.0681 12.6449 13.6974 12.416 13.4685L11.8891 12.9415L12.9434 11.8872L13.4703 12.4142C13.6992 12.6431 14.0699 12.6431 14.2988 12.4142C14.5277 12.1853 14.5277 11.8146 14.2988 11.5857L13.7719 11.0587L14.8262 10.0044L15.3531 10.5314C15.582 10.7603 15.9527 10.7603 16.1817 10.5314C16.4106 10.3025 16.4106 9.93138 16.1817 9.70287L15.6547 9.17592L16.1817 8.64896C16.4106 8.42005 16.4106 8.04896 16.1817 7.82045C16.0672 7.70599 15.9172 7.64896 15.7672 7.64896C15.6172 7.64896 15.4672 7.70599 15.3527 7.82045L14.8258 8.3474L14.2988 7.82045C14.1844 7.70599 14.0344 7.64896 13.8844 7.64896C13.7344 7.64896 13.5844 7.70599 13.4699 7.82045C13.241 8.04935 13.241 8.42045 13.4699 8.64896L13.9969 9.17592L12.9426 10.2302L12.4156 9.70326C12.3012 9.58881 12.1512 9.53177 12.0012 9.53177C11.8512 9.53177 11.7012 9.58881 11.5867 9.70326C11.3578 9.93216 11.3578 10.3033 11.5867 10.5318L12.1137 11.0587L11.0594 12.113L10.5324 11.5861C10.418 11.4716 10.268 11.4146 10.118 11.4146C9.96798 11.4146 9.81798 11.4716 9.70353 11.5861C9.47462 11.815 9.47462 12.1857 9.70353 12.4146L10.2305 12.9415L9.17618 13.9958L8.64923 13.4689C8.53478 13.3544 8.38478 13.2974 8.23478 13.2974C8.08478 13.2974 7.93478 13.3544 7.82033 13.4689C7.59142 13.6978 7.59142 14.0685 7.82033 14.2974L8.34728 14.8244L8.34923 14.8244Z"
        fill="url(#paint1_linear_3332_38787)"
      />
      <path
        d="M2.14258 15.5234L8.47695 21.8578C8.94844 21.7937 9.41602 21.7086 9.87852 21.6019L2.39844 14.1223C2.2918 14.5848 2.20664 15.0519 2.14258 15.5238V15.5234Z"
        fill="url(#paint2_linear_3332_38787)"
      />
      <path
        d="M14.1219 2.3988L21.602 9.87888C21.7086 9.41638 21.7938 8.94919 21.8578 8.47732L15.5235 2.14294C15.0516 2.20701 14.5844 2.29216 14.1219 2.3988Z"
        fill="url(#paint3_linear_3332_38787)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_38787"
          x1="15"
          x2="10.5"
          y1="20.0004"
          y2="3.50037"
        >
          <stop stopColor="#68380C" />
          <stop offset="1" stopColor="#AE714F" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_38787"
          x1="12.001"
          x2="12.001"
          y1="16.3515"
          y2="7.64896"
        >
          <stop stopColor="#CDCDCD" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_3332_38787"
          x1="6.01055"
          x2="6.01055"
          y1="14.1223"
          y2="21.8578"
        >
          <stop stopColor="#CDCDCD" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_3332_38787"
          x1="17.9899"
          x2="17.9899"
          y1="2.14294"
          y2="9.87888"
        >
          <stop stopColor="#CDCDCD" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const TagsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path
        d="M15.6614 6.36966H10.9585C10.4404 6.36966 9.93331 6.57986 9.56762 6.94594L2.57293 13.9406C1.80638 14.7076 1.80638 15.9555 2.57293 16.7224L7.27578 21.4252C8.04428 22.1941 9.28944 22.1938 10.0576 21.4252L17.0526 14.4306C17.4242 14.0586 17.6285 13.5648 17.6285 13.0397V8.33683C17.6285 7.25225 16.7459 6.36966 15.6614 6.36966ZM13.6942 11.6156C12.971 11.6156 12.3826 11.0272 12.3826 10.304C12.3826 9.58082 12.971 8.99242 13.6942 8.99242C14.4174 8.99242 15.0058 9.58082 15.0058 10.304C15.0058 11.0272 14.4174 11.6156 13.6942 11.6156Z"
        fill="url(#paint0_linear_3332_38817)"
      />
      <path
        d="M22 7.02562C22 9.57415 20.0895 11.6816 17.6285 12.0051V10.6806C19.364 10.3704 20.6884 8.84902 20.6884 7.02602C20.6884 4.97563 19.0229 3.31008 16.9725 3.31008C15.1452 3.31008 13.6238 4.63025 13.3179 6.37003C13.2784 6.58413 13.2566 6.80293 13.2566 7.02562C13.2566 7.73396 13.4488 8.41143 13.825 9.00139C14.1134 9.45616 14.5068 9.86249 14.9967 10.1903C15.0053 10.2254 15.0053 10.2645 15.0053 10.304C15.0053 10.7541 14.7736 11.1565 14.4283 11.3925C13.6457 10.8939 13.0382 10.2645 12.6271 9.54329C12.1813 8.7826 11.945 7.9215 11.945 7.02523C11.945 6.80214 11.9583 6.58374 11.9887 6.36964C12.3126 3.90394 14.424 1.99811 16.9725 1.99811C19.7441 1.99811 22 4.254 22 7.02562Z"
        fill="url(#paint1_linear_3332_38817)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3332_38817"
          x1="9.81327"
          x2="9.81327"
          y1="6.36966"
          y2="22.0018"
        >
          <stop stopColor="#E32AF3" />
          <stop offset="1" stopColor="#F32A2A" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3332_38817"
          x1="16.9725"
          x2="16.9725"
          y1="1.99811"
          y2="12.0051"
        >
          <stop offset="0.359375" stopColor="white" />
          <stop offset="1" stopColor="#CDCDCD" />
        </linearGradient>
      </defs>
    </svg>
  )
}
